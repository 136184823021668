export default () => ({
  id: '',
  email: '',
  password: '',
  profile: {
    name: '',
    code: '',
    document: '',
    gender: 1,
    profession: '',
    active: true,
    avatar: {
      name: '',
      path: '',
    },
  },
  students: [],
  role: {
    name: '',
    display: '',
    description: '',
  }
})