import http from './http'

const view = (id) => {
  return http.get('collaborators/view', {
    params: {id}
  })
}

const create = (data) => {
  return http.post('collaborators/create', data)
}

const update = (data) => {
  return http.post('collaborators/update', data)
}

const remove = (data) => {
  return http.post('collaborators/delete', data)
}

export default { view, create, update, remove }