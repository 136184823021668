<template>
  <v-container>
    <v-loading v-model="loading">
        <v-list-item class="px-2">
        <v-list-item-content>
          <v-list-item-title class="title">
            Equipe
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn fab depressed small color="primary" @click="onCreate">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-card outlined>
        <v-simple-table>
          <thead>
            <tr class="text-uppercase">
              <th colspan="100">Nome</th>
            </tr>  
          </thead>    
          <tbody>
            <template v-for="collaborator in collaborators">
              <tr :key="collaborator.id">
                <td>{{ collaborator.name }}</td>
                <td class="text-right">
                  <v-btn icon @click="onEdit(collaborator)">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <v-btn icon :loading="deleting === collaborator.id" :disabled="deleting === collaborator.id" @click="onDelete(collaborator)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-loading>
    <EditCollaboratorDialog
      :collaborator="collaborator"
      v-model="editCollaboratorDialog"
    />
    <CreateCollaboratorDialog
      v-model="createCollaboratorDialog"
    />
  </v-container>
</template>

<script>
  import UserService from '../../services/user'
  import CollaboratorService from '../../services/collaborator'
  import EditCollaboratorDialog from '../../dialogs/EditCollaboratorDialog'
  import CreateCollaboratorDialog from '../../dialogs/CreateCollaboratorDialog'

  export default {
    components: {
      EditCollaboratorDialog,
      CreateCollaboratorDialog
    },
    data: () => ({
      loading: false,
      deleting: null,
      editCollaboratorDialog: false,
      createCollaboratorDialog: false,
      collaborator: {},
      collaborators: [],
    }),
    watch: {
      editCollaboratorDialog(value) {
        if (!value) {
          this.fetchStart()
        }
      },
      createCollaboratorDialog(value) {
        if (!value) {
          this.fetchStart()
        }
      }
    },
    methods: {
      onEdit(collaborator) {
        this.collaborator = collaborator
        this.editCollaboratorDialog = true
      },
      onCreate() {
        this.createCollaboratorDialog = true
      },
      onDelete(collaborator) {
        if (confirm('Deseja realmente remover este Colaborador?')) {  
          this.deleting = collaborator.id;
          CollaboratorService.remove({
            id: collaborator.id,
          }).then(() => {
            this.fetchStart();
          }).catch(() => alert('Não foi possível remover o Colaborador!'))
          .then(() => {
            this.deleting = null;    
          });
        }
      },
      fetchStart() {
        this.loading = true

        UserService.collaborators()
          .then((data) => {
            this.collaborators = data
            this.loading = false
          })
      }
    },
    mounted() {
      this.fetchStart()
    }
  }
</script>
